<template>
<Transition name="slide-fade" appear>
    <div class="md:w-4/6 m-auto bg-white text-t3 container"> 
        <div class="p-12 rounded shadow-t3 items-center relative md:mt-0">


            <h2 class="text-2xl text-center font-bold sm:text-3xl dark:text-white">
                    Add New User
                </h2>

            <div class="w-4/6 m-auto mt-12 ">
                <div class="form-floating mb-3">
                            <input v-model="user.email" type="email" class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                            placeholder="example@email.com" id="floatingInput4" />
                            <label for="floatingInput4" class="text-gray-700" >Email<span class="text-red-600">*</span></label>
                </div>
                <div class="xl:flex justify-center items-center w-full">

                    <div class="text-center">
                        <img v-if="user.profile_img" id='output-image' class="w-48 h-48 mr-4 border-t3-secondary border my-4 object-cover rounded-xl" alt=""/>
                        <label v-if="!user.profile_img" class="w-48 h-48 flex mr-4 flex-col items-center px-4 py-12 bg-white text-blue rounded-lg tracking-wide border border-gray-300 border-dashed cursor-pointer hover:border-t3-teritiary hover:text-white-teritiary">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.0" stroke="currentColor" class="w-12 h-12">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                                            </svg>
                                            <span class="mt-2 text-base leading-normal">Add a Profile Image</span>
                                            <FileInput  id="inputfield" ref="fileupload" accept="image/*" @change="attachmentLoad" v-model="user.profile_img"
                                        class="hidden" />
                        </label>
                        <button @click="user.profile_img = null" v-if="user.profile_img" class="text-sm text-t3-cancel mt-2">X Pick Another</button>
                    </div>
                    <div class="w-full xl:w-4/6">
                        <div class="form-floating mb-1 ">
                            <input v-model="user.first_name" type="text" class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                            placeholder="First Name" id="floatingInput1" />
                            <label for="floatingInput1" class="text-gray-700" >First Name<span class="text-red-600">*</span></label>
                        </div>
                        <div class="form-floating mb-1 ">        
                            <input v-model="user.middle_name" type="text" class="form-control block w-full m-0 px-3 py-1 text-xl font-normal text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                                placeholder="Middle Name" id="floatingInput2" />
                                <label for="floatingInput2" class="text-gray-700" >Middle Name</label>
                        </div>
                        <div class="form-floating ">
                            <input v-model="user.last_name" type="text" class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                                placeholder="Last Name" id="floatingInput3"/>
                                <label for="floatingInput3" class="text-gray-700" >Last Name<span class="text-red-600">*</span></label>
                        </div>
                    </div>
                </div>
                <!-- <div class="py-4 flex justify-between w-full">
                        <div class=" space-y-2">
                            <div class=" flex justify-items-start items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-t3-mutedText w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                    </svg>
                                    <select v-model="customer_profile.customer_company"  class="form-control w-56 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border  border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" :class="{'border-none disdrop':editProf}" >
                                        <option :value="null"> Add Organization</option>
                                        <option v-for="organization in organizations" :key="organization.id" :value="organization.id">{{organization.organization_name}}</option>
                                    </select>
                            </div>
                            <div class=" flex justify-items-start items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-t3-mutedText">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                </svg>
                                <link href="https://cdn.jsdelivr.net/npm/@tailwindcss/custom-forms@0.2.1/dist/custom-forms.css" rel="stylesheet" />
                                <select v-model="customer_profile.other_affiliations"  class="form-multiselect block w-56 px-3 py-1.5 text-base font-normal mt-6 text-t3 bg-white bg-clip-padding border  border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" :class="{'border-none disdrop':editProf}" multiple >
                                <option v-for="organization in organizations" :key="organization.id" :value="organization.id">{{organization.organization_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex-col flex justify-around">
                            <div class=" flex justify-items-start items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" text-t3-mutedText w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                </svg>
                                <select v-model="customer_profile.license_type" :disabled="editProf" class="form-control w-56 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border  border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" :class="{'border-none disdrop':editProf}" >
                                    <option :value="null" disabled> Add License Type</option>
                                    <option v-for="typ in types" :key="typ.id" :value="typ.id">{{typ.name}}</option>
                                </select>
                            </div>
                            <div class=" flex justify-items-start m-auto items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-t3-mutedText">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                                </svg>
                                <select v-model="customer_profile.party"  class="form-control w-56 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border  border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" :class="{'border-none disdrop':editProf}" >
                                    <option :value="null"> Choose Party</option>
                                    <option value="individual">Individual</option>
                                    <option value="company">Company</option>
                                </select>
                            </div>
                        </div>
                </div> -->
                <div class="my-4">
                    <label for="datepcik" class="text-gray-700 text-left text-sm" >Date of Birth<span class="text-red-600">*</span></label>
                    <Datepicker id="datepick" v-model="user.dob" :enableTimePicker="false" :max-date="new Date('1/1/2012')" datePicker placeholder="Select a Date"  class="form-control block w-full  px-3 p-y-1.5 text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none"/>
                </div>
                <div class="my-4">
                    <label for="id_POB" class="text-gray-700 text-left text-sm" >Place of Birth<span class="text-red-600">*</span></label>
                    <select placeholder="Select a Country" class="form-select form-select-lg mb-3
                        appearance-none
                        block
                        w-full
                        px-4
                        py-2
                        text-xl
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" v-model="user.place_of_birth" id="id_POB">
                    <option :value="null" disabled>Select country</option>
                    
                    <option value="AF">Afghanistan</option>
                    
                    <option value="AX">Åland Islands</option>
                    
                    <option value="AL">Albania</option>
                    
                    <option value="DZ">Algeria</option>
                    
                    <option value="AS">American Samoa</option>
                    
                    <option value="AD">Andorra</option>
                    
                    <option value="AO">Angola</option>
                    
                    <option value="AI">Anguilla</option>
                    
                    <option value="AQ">Antarctica</option>
                    
                    <option value="AG">Antigua and Barbuda</option>
                    
                    <option value="AR">Argentina</option>
                    
                    <option value="AM">Armenia</option>
                    
                    <option value="AW">Aruba</option>
                    
                    <option value="AU">Australia</option>
                    
                    <option value="AT">Austria</option>
                    
                    <option value="AZ">Azerbaijan</option>
                    
                    <option value="BS">Bahamas</option>
                    
                    <option value="BH">Bahrain</option>
                    
                    <option value="BD">Bangladesh</option>
                    
                    <option value="BB">Barbados</option>
                    
                    <option value="BY">Belarus</option>
                    
                    <option value="BE">Belgium</option>
                    
                    <option value="BZ">Belize</option>
                    
                    <option value="BJ">Benin</option>
                    
                    <option value="BM">Bermuda</option>
                    
                    <option value="BT">Bhutan</option>
                    
                    <option value="BO">Bolivia</option>
                    
                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                    
                    <option value="BA">Bosnia and Herzegovina</option>
                    
                    <option value="BW">Botswana</option>
                    
                    <option value="BV">Bouvet Island</option>
                    
                    <option value="BR">Brazil</option>
                    
                    <option value="IO">British Indian Ocean Territory</option>
                    
                    <option value="BN">Brunei</option>
                    
                    <option value="BG">Bulgaria</option>
                    
                    <option value="BF">Burkina Faso</option>
                    
                    <option value="BI">Burundi</option>
                    
                    <option value="CV">Cabo Verde</option>
                    
                    <option value="KH">Cambodia</option>
                    
                    <option value="CM">Cameroon</option>
                    
                    <option value="CA">Canada</option>
                    
                    <option value="KY">Cayman Islands</option>
                    
                    <option value="CF">Central African Republic</option>
                    
                    <option value="TD">Chad</option>
                    
                    <option value="CL">Chile</option>
                    
                    <option value="CN">China</option>
                    
                    <option value="CX">Christmas Island</option>
                    
                    <option value="CC">Cocos (Keeling) Islands</option>
                    
                    <option value="CO">Colombia</option>
                    
                    <option value="KM">Comoros</option>
                    
                    <option value="CG">Congo</option>
                    
                    <option value="CD">Congo (the Democratic Republic of the)</option>
                    
                    <option value="CK">Cook Islands</option>
                    
                    <option value="CR">Costa Rica</option>
                    
                    <option value="CI">Côte d&#x27;Ivoire</option>
                    
                    <option value="HR">Croatia</option>
                    
                    <option value="CU">Cuba</option>
                    
                    <option value="CW">Curaçao</option>
                    
                    <option value="CY">Cyprus</option>
                    
                    <option value="CZ">Czechia</option>
                    
                    <option value="DK">Denmark</option>
                    
                    <option value="DJ">Djibouti</option>
                    
                    <option value="DM">Dominica</option>
                    
                    <option value="DO">Dominican Republic</option>
                    
                    <option value="EC">Ecuador</option>
                    
                    <option value="EG">Egypt</option>
                    
                    <option value="SV">El Salvador</option>
                    
                    <option value="GQ">Equatorial Guinea</option>
                    
                    <option value="ER">Eritrea</option>
                    
                    <option value="EE">Estonia</option>
                    
                    <option value="SZ">Eswatini</option>
                    
                    <option value="ET">Ethiopia</option>
                    
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    
                    <option value="FO">Faroe Islands</option>
                    
                    <option value="FJ">Fiji</option>
                    
                    <option value="FI">Finland</option>
                    
                    <option value="FR">France</option>
                    
                    <option value="GF">French Guiana</option>
                    
                    <option value="PF">French Polynesia</option>
                    
                    <option value="TF">French Southern Territories</option>
                    
                    <option value="GA">Gabon</option>
                    
                    <option value="GM">Gambia</option>
                    
                    <option value="GE">Georgia</option>
                    
                    <option value="DE">Germany</option>
                    
                    <option value="GH">Ghana</option>
                    
                    <option value="GI">Gibraltar</option>
                    
                    <option value="GR">Greece</option>
                    
                    <option value="GL">Greenland</option>
                    
                    <option value="GD">Grenada</option>
                    
                    <option value="GP">Guadeloupe</option>
                    
                    <option value="GU">Guam</option>
                    
                    <option value="GT">Guatemala</option>
                    
                    <option value="GG">Guernsey</option>
                    
                    <option value="GN">Guinea</option>
                    
                    <option value="GW">Guinea-Bissau</option>
                    
                    <option value="GY">Guyana</option>
                    
                    <option value="HT">Haiti</option>
                    
                    <option value="HM">Heard Island and McDonald Islands</option>
                    
                    <option value="VA">Holy See</option>
                    
                    <option value="HN">Honduras</option>
                    
                    <option value="HK">Hong Kong</option>
                    
                    <option value="HU">Hungary</option>
                    
                    <option value="IS">Iceland</option>
                    
                    <option value="IN">India</option>
                    
                    <option value="ID">Indonesia</option>
                    
                    <option value="IR">Iran</option>
                    
                    <option value="IQ">Iraq</option>
                    
                    <option value="IE">Ireland</option>
                    
                    <option value="IM">Isle of Man</option>
                    
                    <option value="IL">Israel</option>
                    
                    <option value="IT">Italy</option>
                    
                    <option value="JM">Jamaica</option>
                    
                    <option value="JP">Japan</option>
                    
                    <option value="JE">Jersey</option>
                    
                    <option value="JO">Jordan</option>
                    
                    <option value="KZ">Kazakhstan</option>
                    
                    <option value="KE">Kenya</option>
                    
                    <option value="KI">Kiribati</option>
                    
                    <option value="KW">Kuwait</option>
                    
                    <option value="KG">Kyrgyzstan</option>
                    
                    <option value="LA">Laos</option>
                    
                    <option value="LV">Latvia</option>
                    
                    <option value="LB">Lebanon</option>
                    
                    <option value="LS">Lesotho</option>
                    
                    <option value="LR">Liberia</option>
                    
                    <option value="LY">Libya</option>
                    
                    <option value="LI">Liechtenstein</option>
                    
                    <option value="LT">Lithuania</option>
                    
                    <option value="LU">Luxembourg</option>
                    
                    <option value="MO">Macao</option>
                    
                    <option value="MG">Madagascar</option>
                    
                    <option value="MW">Malawi</option>
                    
                    <option value="MY">Malaysia</option>
                    
                    <option value="MV">Maldives</option>
                    
                    <option value="ML">Mali</option>
                    
                    <option value="MT">Malta</option>
                    
                    <option value="MH">Marshall Islands</option>
                    
                    <option value="MQ">Martinique</option>
                    
                    <option value="MR">Mauritania</option>
                    
                    <option value="MU">Mauritius</option>
                    
                    <option value="YT">Mayotte</option>
                    
                    <option value="MX">Mexico</option>
                    
                    <option value="FM">Micronesia (Federated States of)</option>
                    
                    <option value="MD">Moldova</option>
                    
                    <option value="MC">Monaco</option>
                    
                    <option value="MN">Mongolia</option>
                    
                    <option value="ME">Montenegro</option>
                    
                    <option value="MS">Montserrat</option>
                    
                    <option value="MA">Morocco</option>
                    
                    <option value="MZ">Mozambique</option>
                    
                    <option value="MM">Myanmar</option>
                    
                    <option value="NA">Namibia</option>
                    
                    <option value="NR">Nauru</option>
                    
                    <option value="NP">Nepal</option>
                    
                    <option value="NL">Netherlands</option>
                    
                    <option value="NC">New Caledonia</option>
                    
                    <option value="NZ">New Zealand</option>
                    
                    <option value="NI">Nicaragua</option>
                    
                    <option value="NE">Niger</option>
                    
                    <option value="NG">Nigeria</option>
                    
                    <option value="NU">Niue</option>
                    
                    <option value="NF">Norfolk Island</option>
                    
                    <option value="KP">North Korea</option>
                    
                    <option value="MK">North Macedonia</option>
                    
                    <option value="MP">Northern Mariana Islands</option>
                    
                    <option value="NO">Norway</option>
                    
                    <option value="OM">Oman</option>
                    
                    <option value="PK">Pakistan</option>
                    
                    <option value="PW">Palau</option>
                    
                    <option value="PS">Palestine, State of</option>
                    
                    <option value="PA">Panama</option>
                    
                    <option value="PG">Papua New Guinea</option>
                    
                    <option value="PY">Paraguay</option>
                    
                    <option value="PE">Peru</option>
                    
                    <option value="PH">Philippines</option>
                    
                    <option value="PN">Pitcairn</option>
                    
                    <option value="PL">Poland</option>
                    
                    <option value="PT">Portugal</option>
                    
                    <option value="PR">Puerto Rico</option>
                    
                    <option value="QA">Qatar</option>
                    
                    <option value="RE">Réunion</option>
                    
                    <option value="RO">Romania</option>
                    
                    <option value="RU">Russia</option>
                    
                    <option value="RW">Rwanda</option>
                    
                    <option value="BL">Saint Barthélemy</option>
                    
                    <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                    
                    <option value="KN">Saint Kitts and Nevis</option>
                    
                    <option value="LC">Saint Lucia</option>
                    
                    <option value="MF">Saint Martin (French part)</option>
                    
                    <option value="PM">Saint Pierre and Miquelon</option>
                    
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    
                    <option value="WS">Samoa</option>
                    
                    <option value="SM">San Marino</option>
                    
                    <option value="ST">Sao Tome and Principe</option>
                    
                    <option value="SA">Saudi Arabia</option>
                    
                    <option value="SN">Senegal</option>
                    
                    <option value="RS">Serbia</option>
                    
                    <option value="SC">Seychelles</option>
                    
                    <option value="SL">Sierra Leone</option>
                    
                    <option value="SG">Singapore</option>
                    
                    <option value="SX">Sint Maarten (Dutch part)</option>
                    
                    <option value="SK">Slovakia</option>
                    
                    <option value="SI">Slovenia</option>
                    
                    <option value="SB">Solomon Islands</option>
                    
                    <option value="SO">Somalia</option>
                    
                    <option value="ZA">South Africa</option>
                    
                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                    
                    <option value="KR">South Korea</option>
                    
                    <option value="SS">South Sudan</option>
                    
                    <option value="ES">Spain</option>
                    
                    <option value="LK">Sri Lanka</option>
                    
                    <option value="SD">Sudan</option>
                    
                    <option value="SR">Suriname</option>
                    
                    <option value="SJ">Svalbard and Jan Mayen</option>
                    
                    <option value="SE">Sweden</option>
                    
                    <option value="CH">Switzerland</option>
                    
                    <option value="SY">Syria</option>
                    
                    <option value="TW">Taiwan</option>
                    
                    <option value="TJ">Tajikistan</option>
                    
                    <option value="TZ">Tanzania</option>
                    
                    <option value="TH">Thailand</option>
                    
                    <option value="TL">Timor-Leste</option>
                    
                    <option value="TG">Togo</option>
                    
                    <option value="TK">Tokelau</option>
                    
                    <option value="TO">Tonga</option>
                    
                    <option value="TT">Trinidad and Tobago</option>
                    
                    <option value="TN">Tunisia</option>
                    
                    <option value="TR">Turkey</option>
                    
                    <option value="TM">Turkmenistan</option>
                    
                    <option value="TC">Turks and Caicos Islands</option>
                    
                    <option value="TV">Tuvalu</option>
                    
                    <option value="UG">Uganda</option>
                    
                    <option value="UA">Ukraine</option>
                    
                    <option value="AE">United Arab Emirates</option>
                    
                    <option value="GB">United Kingdom</option>
                    
                    <option value="UM">United States Minor Outlying Islands</option>
                    
                    <option value="US">United States of America</option>
                    
                    <option value="UY">Uruguay</option>
                    
                    <option value="UZ">Uzbekistan</option>
                    
                    <option value="VU">Vanuatu</option>
                    
                    <option value="VE">Venezuela</option>
                    
                    <option value="VN">Vietnam</option>
                    
                    <option value="VG">Virgin Islands (British)</option>
                    
                    <option value="VI">Virgin Islands (U.S.)</option>
                    
                    <option value="WF">Wallis and Futuna</option>
                    
                    <option value="EH">Western Sahara</option>
                    
                    <option value="YE">Yemen</option>
                    
                    <option value="ZM">Zambia</option>
                    
                    <option value="ZW">Zimbabwe</option>
                    
                    </select>
                </div>
                <div class="my-4">
                    <label for="id_nationality" class="text-gray-700 text-left text-sm" >Nationality<span class="text-red-600">*</span></label>
                    <select placeholder="Select a Country" class="form-select form-select-lg mb-3
                        appearance-none
                        block
                        w-full
                        px-4
                        py-2
                        text-xl
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" v-model="user.nationality" id="id_nationality">
                    <option :value="null" disabled>Select country</option>
                    
                    <option value="AF">Afghanistan</option>
                    
                    <option value="AX">Åland Islands</option>
                    
                    <option value="AL">Albania</option>
                    
                    <option value="DZ">Algeria</option>
                    
                    <option value="AS">American Samoa</option>
                    
                    <option value="AD">Andorra</option>
                    
                    <option value="AO">Angola</option>
                    
                    <option value="AI">Anguilla</option>
                    
                    <option value="AQ">Antarctica</option>
                    
                    <option value="AG">Antigua and Barbuda</option>
                    
                    <option value="AR">Argentina</option>
                    
                    <option value="AM">Armenia</option>
                    
                    <option value="AW">Aruba</option>
                    
                    <option value="AU">Australia</option>
                    
                    <option value="AT">Austria</option>
                    
                    <option value="AZ">Azerbaijan</option>
                    
                    <option value="BS">Bahamas</option>
                    
                    <option value="BH">Bahrain</option>
                    
                    <option value="BD">Bangladesh</option>
                    
                    <option value="BB">Barbados</option>
                    
                    <option value="BY">Belarus</option>
                    
                    <option value="BE">Belgium</option>
                    
                    <option value="BZ">Belize</option>
                    
                    <option value="BJ">Benin</option>
                    
                    <option value="BM">Bermuda</option>
                    
                    <option value="BT">Bhutan</option>
                    
                    <option value="BO">Bolivia</option>
                    
                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                    
                    <option value="BA">Bosnia and Herzegovina</option>
                    
                    <option value="BW">Botswana</option>
                    
                    <option value="BV">Bouvet Island</option>
                    
                    <option value="BR">Brazil</option>
                    
                    <option value="IO">British Indian Ocean Territory</option>
                    
                    <option value="BN">Brunei</option>
                    
                    <option value="BG">Bulgaria</option>
                    
                    <option value="BF">Burkina Faso</option>
                    
                    <option value="BI">Burundi</option>
                    
                    <option value="CV">Cabo Verde</option>
                    
                    <option value="KH">Cambodia</option>
                    
                    <option value="CM">Cameroon</option>
                    
                    <option value="CA">Canada</option>
                    
                    <option value="KY">Cayman Islands</option>
                    
                    <option value="CF">Central African Republic</option>
                    
                    <option value="TD">Chad</option>
                    
                    <option value="CL">Chile</option>
                    
                    <option value="CN">China</option>
                    
                    <option value="CX">Christmas Island</option>
                    
                    <option value="CC">Cocos (Keeling) Islands</option>
                    
                    <option value="CO">Colombia</option>
                    
                    <option value="KM">Comoros</option>
                    
                    <option value="CG">Congo</option>
                    
                    <option value="CD">Congo (the Democratic Republic of the)</option>
                    
                    <option value="CK">Cook Islands</option>
                    
                    <option value="CR">Costa Rica</option>
                    
                    <option value="CI">Côte d&#x27;Ivoire</option>
                    
                    <option value="HR">Croatia</option>
                    
                    <option value="CU">Cuba</option>
                    
                    <option value="CW">Curaçao</option>
                    
                    <option value="CY">Cyprus</option>
                    
                    <option value="CZ">Czechia</option>
                    
                    <option value="DK">Denmark</option>
                    
                    <option value="DJ">Djibouti</option>
                    
                    <option value="DM">Dominica</option>
                    
                    <option value="DO">Dominican Republic</option>
                    
                    <option value="EC">Ecuador</option>
                    
                    <option value="EG">Egypt</option>
                    
                    <option value="SV">El Salvador</option>
                    
                    <option value="GQ">Equatorial Guinea</option>
                    
                    <option value="ER">Eritrea</option>
                    
                    <option value="EE">Estonia</option>
                    
                    <option value="SZ">Eswatini</option>
                    
                    <option value="ET">Ethiopia</option>
                    
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    
                    <option value="FO">Faroe Islands</option>
                    
                    <option value="FJ">Fiji</option>
                    
                    <option value="FI">Finland</option>
                    
                    <option value="FR">France</option>
                    
                    <option value="GF">French Guiana</option>
                    
                    <option value="PF">French Polynesia</option>
                    
                    <option value="TF">French Southern Territories</option>
                    
                    <option value="GA">Gabon</option>
                    
                    <option value="GM">Gambia</option>
                    
                    <option value="GE">Georgia</option>
                    
                    <option value="DE">Germany</option>
                    
                    <option value="GH">Ghana</option>
                    
                    <option value="GI">Gibraltar</option>
                    
                    <option value="GR">Greece</option>
                    
                    <option value="GL">Greenland</option>
                    
                    <option value="GD">Grenada</option>
                    
                    <option value="GP">Guadeloupe</option>
                    
                    <option value="GU">Guam</option>
                    
                    <option value="GT">Guatemala</option>
                    
                    <option value="GG">Guernsey</option>
                    
                    <option value="GN">Guinea</option>
                    
                    <option value="GW">Guinea-Bissau</option>
                    
                    <option value="GY">Guyana</option>
                    
                    <option value="HT">Haiti</option>
                    
                    <option value="HM">Heard Island and McDonald Islands</option>
                    
                    <option value="VA">Holy See</option>
                    
                    <option value="HN">Honduras</option>
                    
                    <option value="HK">Hong Kong</option>
                    
                    <option value="HU">Hungary</option>
                    
                    <option value="IS">Iceland</option>
                    
                    <option value="IN">India</option>
                    
                    <option value="ID">Indonesia</option>
                    
                    <option value="IR">Iran</option>
                    
                    <option value="IQ">Iraq</option>
                    
                    <option value="IE">Ireland</option>
                    
                    <option value="IM">Isle of Man</option>
                    
                    <option value="IL">Israel</option>
                    
                    <option value="IT">Italy</option>
                    
                    <option value="JM">Jamaica</option>
                    
                    <option value="JP">Japan</option>
                    
                    <option value="JE">Jersey</option>
                    
                    <option value="JO">Jordan</option>
                    
                    <option value="KZ">Kazakhstan</option>
                    
                    <option value="KE">Kenya</option>
                    
                    <option value="KI">Kiribati</option>
                    
                    <option value="KW">Kuwait</option>
                    
                    <option value="KG">Kyrgyzstan</option>
                    
                    <option value="LA">Laos</option>
                    
                    <option value="LV">Latvia</option>
                    
                    <option value="LB">Lebanon</option>
                    
                    <option value="LS">Lesotho</option>
                    
                    <option value="LR">Liberia</option>
                    
                    <option value="LY">Libya</option>
                    
                    <option value="LI">Liechtenstein</option>
                    
                    <option value="LT">Lithuania</option>
                    
                    <option value="LU">Luxembourg</option>
                    
                    <option value="MO">Macao</option>
                    
                    <option value="MG">Madagascar</option>
                    
                    <option value="MW">Malawi</option>
                    
                    <option value="MY">Malaysia</option>
                    
                    <option value="MV">Maldives</option>
                    
                    <option value="ML">Mali</option>
                    
                    <option value="MT">Malta</option>
                    
                    <option value="MH">Marshall Islands</option>
                    
                    <option value="MQ">Martinique</option>
                    
                    <option value="MR">Mauritania</option>
                    
                    <option value="MU">Mauritius</option>
                    
                    <option value="YT">Mayotte</option>
                    
                    <option value="MX">Mexico</option>
                    
                    <option value="FM">Micronesia (Federated States of)</option>
                    
                    <option value="MD">Moldova</option>
                    
                    <option value="MC">Monaco</option>
                    
                    <option value="MN">Mongolia</option>
                    
                    <option value="ME">Montenegro</option>
                    
                    <option value="MS">Montserrat</option>
                    
                    <option value="MA">Morocco</option>
                    
                    <option value="MZ">Mozambique</option>
                    
                    <option value="MM">Myanmar</option>
                    
                    <option value="NA">Namibia</option>
                    
                    <option value="NR">Nauru</option>
                    
                    <option value="NP">Nepal</option>
                    
                    <option value="NL">Netherlands</option>
                    
                    <option value="NC">New Caledonia</option>
                    
                    <option value="NZ">New Zealand</option>
                    
                    <option value="NI">Nicaragua</option>
                    
                    <option value="NE">Niger</option>
                    
                    <option value="NG">Nigeria</option>
                    
                    <option value="NU">Niue</option>
                    
                    <option value="NF">Norfolk Island</option>
                    
                    <option value="KP">North Korea</option>
                    
                    <option value="MK">North Macedonia</option>
                    
                    <option value="MP">Northern Mariana Islands</option>
                    
                    <option value="NO">Norway</option>
                    
                    <option value="OM">Oman</option>
                    
                    <option value="PK">Pakistan</option>
                    
                    <option value="PW">Palau</option>
                    
                    <option value="PS">Palestine, State of</option>
                    
                    <option value="PA">Panama</option>
                    
                    <option value="PG">Papua New Guinea</option>
                    
                    <option value="PY">Paraguay</option>
                    
                    <option value="PE">Peru</option>
                    
                    <option value="PH">Philippines</option>
                    
                    <option value="PN">Pitcairn</option>
                    
                    <option value="PL">Poland</option>
                    
                    <option value="PT">Portugal</option>
                    
                    <option value="PR">Puerto Rico</option>
                    
                    <option value="QA">Qatar</option>
                    
                    <option value="RE">Réunion</option>
                    
                    <option value="RO">Romania</option>
                    
                    <option value="RU">Russia</option>
                    
                    <option value="RW">Rwanda</option>
                    
                    <option value="BL">Saint Barthélemy</option>
                    
                    <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                    
                    <option value="KN">Saint Kitts and Nevis</option>
                    
                    <option value="LC">Saint Lucia</option>
                    
                    <option value="MF">Saint Martin (French part)</option>
                    
                    <option value="PM">Saint Pierre and Miquelon</option>
                    
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    
                    <option value="WS">Samoa</option>
                    
                    <option value="SM">San Marino</option>
                    
                    <option value="ST">Sao Tome and Principe</option>
                    
                    <option value="SA">Saudi Arabia</option>
                    
                    <option value="SN">Senegal</option>
                    
                    <option value="RS">Serbia</option>
                    
                    <option value="SC">Seychelles</option>
                    
                    <option value="SL">Sierra Leone</option>
                    
                    <option value="SG">Singapore</option>
                    
                    <option value="SX">Sint Maarten (Dutch part)</option>
                    
                    <option value="SK">Slovakia</option>
                    
                    <option value="SI">Slovenia</option>
                    
                    <option value="SB">Solomon Islands</option>
                    
                    <option value="SO">Somalia</option>
                    
                    <option value="ZA">South Africa</option>
                    
                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                    
                    <option value="KR">South Korea</option>
                    
                    <option value="SS">South Sudan</option>
                    
                    <option value="ES">Spain</option>
                    
                    <option value="LK">Sri Lanka</option>
                    
                    <option value="SD">Sudan</option>
                    
                    <option value="SR">Suriname</option>
                    
                    <option value="SJ">Svalbard and Jan Mayen</option>
                    
                    <option value="SE">Sweden</option>
                    
                    <option value="CH">Switzerland</option>
                    
                    <option value="SY">Syria</option>
                    
                    <option value="TW">Taiwan</option>
                    
                    <option value="TJ">Tajikistan</option>
                    
                    <option value="TZ">Tanzania</option>
                    
                    <option value="TH">Thailand</option>
                    
                    <option value="TL">Timor-Leste</option>
                    
                    <option value="TG">Togo</option>
                    
                    <option value="TK">Tokelau</option>
                    
                    <option value="TO">Tonga</option>
                    
                    <option value="TT">Trinidad and Tobago</option>
                    
                    <option value="TN">Tunisia</option>
                    
                    <option value="TR">Turkey</option>
                    
                    <option value="TM">Turkmenistan</option>
                    
                    <option value="TC">Turks and Caicos Islands</option>
                    
                    <option value="TV">Tuvalu</option>
                    
                    <option value="UG">Uganda</option>
                    
                    <option value="UA">Ukraine</option>
                    
                    <option value="AE">United Arab Emirates</option>
                    
                    <option value="GB">United Kingdom</option>
                    
                    <option value="UM">United States Minor Outlying Islands</option>
                    
                    <option value="US">United States of America</option>
                    
                    <option value="UY">Uruguay</option>
                    
                    <option value="UZ">Uzbekistan</option>
                    
                    <option value="VU">Vanuatu</option>
                    
                    <option value="VE">Venezuela</option>
                    
                    <option value="VN">Vietnam</option>
                    
                    <option value="VG">Virgin Islands (British)</option>
                    
                    <option value="VI">Virgin Islands (U.S.)</option>
                    
                    <option value="WF">Wallis and Futuna</option>
                    
                    <option value="EH">Western Sahara</option>
                    
                    <option value="YE">Yemen</option>
                    
                    <option value="ZM">Zambia</option>
                    
                    <option value="ZW">Zimbabwe</option>
                    
                    </select>
                </div>
                <div class=" my-4">
                    <label for="id_customer-dual_nationality" class="text-gray-700 text-left text-sm" >Past/Dual Nationality</label>
                    <select placeholder="Select a Country" class="form-select form-select-lg mb-3
                        appearance-none
                        block
                        w-full
                        px-4
                        py-2
                        text-xl
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" v-model="user.dual_nationality" id="id_customer-dual_nationality">
                    <option :value=null>Select country</option>
                    
                    <option value="AF">Afghanistan</option>
                    
                    <option value="AX">Åland Islands</option>
                    
                    <option value="AL">Albania</option>
                    
                    <option value="DZ">Algeria</option>
                    
                    <option value="AS">American Samoa</option>
                    
                    <option value="AD">Andorra</option>
                    
                    <option value="AO">Angola</option>
                    
                    <option value="AI">Anguilla</option>
                    
                    <option value="AQ">Antarctica</option>
                    
                    <option value="AG">Antigua and Barbuda</option>
                    
                    <option value="AR">Argentina</option>
                    
                    <option value="AM">Armenia</option>
                    
                    <option value="AW">Aruba</option>
                    
                    <option value="AU">Australia</option>
                    
                    <option value="AT">Austria</option>
                    
                    <option value="AZ">Azerbaijan</option>
                    
                    <option value="BS">Bahamas</option>
                    
                    <option value="BH">Bahrain</option>
                    
                    <option value="BD">Bangladesh</option>
                    
                    <option value="BB">Barbados</option>
                    
                    <option value="BY">Belarus</option>
                    
                    <option value="BE">Belgium</option>
                    
                    <option value="BZ">Belize</option>
                    
                    <option value="BJ">Benin</option>
                    
                    <option value="BM">Bermuda</option>
                    
                    <option value="BT">Bhutan</option>
                    
                    <option value="BO">Bolivia</option>
                    
                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                    
                    <option value="BA">Bosnia and Herzegovina</option>
                    
                    <option value="BW">Botswana</option>
                    
                    <option value="BV">Bouvet Island</option>
                    
                    <option value="BR">Brazil</option>
                    
                    <option value="IO">British Indian Ocean Territory</option>
                    
                    <option value="BN">Brunei</option>
                    
                    <option value="BG">Bulgaria</option>
                    
                    <option value="BF">Burkina Faso</option>
                    
                    <option value="BI">Burundi</option>
                    
                    <option value="CV">Cabo Verde</option>
                    
                    <option value="KH">Cambodia</option>
                    
                    <option value="CM">Cameroon</option>
                    
                    <option value="CA">Canada</option>
                    
                    <option value="KY">Cayman Islands</option>
                    
                    <option value="CF">Central African Republic</option>
                    
                    <option value="TD">Chad</option>
                    
                    <option value="CL">Chile</option>
                    
                    <option value="CN">China</option>
                    
                    <option value="CX">Christmas Island</option>
                    
                    <option value="CC">Cocos (Keeling) Islands</option>
                    
                    <option value="CO">Colombia</option>
                    
                    <option value="KM">Comoros</option>
                    
                    <option value="CG">Congo</option>
                    
                    <option value="CD">Congo (the Democratic Republic of the)</option>
                    
                    <option value="CK">Cook Islands</option>
                    
                    <option value="CR">Costa Rica</option>
                    
                    <option value="CI">Côte d&#x27;Ivoire</option>
                    
                    <option value="HR">Croatia</option>
                    
                    <option value="CU">Cuba</option>
                    
                    <option value="CW">Curaçao</option>
                    
                    <option value="CY">Cyprus</option>
                    
                    <option value="CZ">Czechia</option>
                    
                    <option value="DK">Denmark</option>
                    
                    <option value="DJ">Djibouti</option>
                    
                    <option value="DM">Dominica</option>
                    
                    <option value="DO">Dominican Republic</option>
                    
                    <option value="EC">Ecuador</option>
                    
                    <option value="EG">Egypt</option>
                    
                    <option value="SV">El Salvador</option>
                    
                    <option value="GQ">Equatorial Guinea</option>
                    
                    <option value="ER">Eritrea</option>
                    
                    <option value="EE">Estonia</option>
                    
                    <option value="SZ">Eswatini</option>
                    
                    <option value="ET">Ethiopia</option>
                    
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    
                    <option value="FO">Faroe Islands</option>
                    
                    <option value="FJ">Fiji</option>
                    
                    <option value="FI">Finland</option>
                    
                    <option value="FR">France</option>
                    
                    <option value="GF">French Guiana</option>
                    
                    <option value="PF">French Polynesia</option>
                    
                    <option value="TF">French Southern Territories</option>
                    
                    <option value="GA">Gabon</option>
                    
                    <option value="GM">Gambia</option>
                    
                    <option value="GE">Georgia</option>
                    
                    <option value="DE">Germany</option>
                    
                    <option value="GH">Ghana</option>
                    
                    <option value="GI">Gibraltar</option>
                    
                    <option value="GR">Greece</option>
                    
                    <option value="GL">Greenland</option>
                    
                    <option value="GD">Grenada</option>
                    
                    <option value="GP">Guadeloupe</option>
                    
                    <option value="GU">Guam</option>
                    
                    <option value="GT">Guatemala</option>
                    
                    <option value="GG">Guernsey</option>
                    
                    <option value="GN">Guinea</option>
                    
                    <option value="GW">Guinea-Bissau</option>
                    
                    <option value="GY">Guyana</option>
                    
                    <option value="HT">Haiti</option>
                    
                    <option value="HM">Heard Island and McDonald Islands</option>
                    
                    <option value="VA">Holy See</option>
                    
                    <option value="HN">Honduras</option>
                    
                    <option value="HK">Hong Kong</option>
                    
                    <option value="HU">Hungary</option>
                    
                    <option value="IS">Iceland</option>
                    
                    <option value="IN">India</option>
                    
                    <option value="ID">Indonesia</option>
                    
                    <option value="IR">Iran</option>
                    
                    <option value="IQ">Iraq</option>
                    
                    <option value="IE">Ireland</option>
                    
                    <option value="IM">Isle of Man</option>
                    
                    <option value="IL">Israel</option>
                    
                    <option value="IT">Italy</option>
                    
                    <option value="JM">Jamaica</option>
                    
                    <option value="JP">Japan</option>
                    
                    <option value="JE">Jersey</option>
                    
                    <option value="JO">Jordan</option>
                    
                    <option value="KZ">Kazakhstan</option>
                    
                    <option value="KE">Kenya</option>
                    
                    <option value="KI">Kiribati</option>
                    
                    <option value="KW">Kuwait</option>
                    
                    <option value="KG">Kyrgyzstan</option>
                    
                    <option value="LA">Laos</option>
                    
                    <option value="LV">Latvia</option>
                    
                    <option value="LB">Lebanon</option>
                    
                    <option value="LS">Lesotho</option>
                    
                    <option value="LR">Liberia</option>
                    
                    <option value="LY">Libya</option>
                    
                    <option value="LI">Liechtenstein</option>
                    
                    <option value="LT">Lithuania</option>
                    
                    <option value="LU">Luxembourg</option>
                    
                    <option value="MO">Macao</option>
                    
                    <option value="MG">Madagascar</option>
                    
                    <option value="MW">Malawi</option>
                    
                    <option value="MY">Malaysia</option>
                    
                    <option value="MV">Maldives</option>
                    
                    <option value="ML">Mali</option>
                    
                    <option value="MT">Malta</option>
                    
                    <option value="MH">Marshall Islands</option>
                    
                    <option value="MQ">Martinique</option>
                    
                    <option value="MR">Mauritania</option>
                    
                    <option value="MU">Mauritius</option>
                    
                    <option value="YT">Mayotte</option>
                    
                    <option value="MX">Mexico</option>
                    
                    <option value="FM">Micronesia (Federated States of)</option>
                    
                    <option value="MD">Moldova</option>
                    
                    <option value="MC">Monaco</option>
                    
                    <option value="MN">Mongolia</option>
                    
                    <option value="ME">Montenegro</option>
                    
                    <option value="MS">Montserrat</option>
                    
                    <option value="MA">Morocco</option>
                    
                    <option value="MZ">Mozambique</option>
                    
                    <option value="MM">Myanmar</option>
                    
                    <option value="NA">Namibia</option>
                    
                    <option value="NR">Nauru</option>
                    
                    <option value="NP">Nepal</option>
                    
                    <option value="NL">Netherlands</option>
                    
                    <option value="NC">New Caledonia</option>
                    
                    <option value="NZ">New Zealand</option>
                    
                    <option value="NI">Nicaragua</option>
                    
                    <option value="NE">Niger</option>
                    
                    <option value="NG">Nigeria</option>
                    
                    <option value="NU">Niue</option>
                    
                    <option value="NF">Norfolk Island</option>
                    
                    <option value="KP">North Korea</option>
                    
                    <option value="MK">North Macedonia</option>
                    
                    <option value="MP">Northern Mariana Islands</option>
                    
                    <option value="NO">Norway</option>
                    
                    <option value="OM">Oman</option>
                    
                    <option value="PK">Pakistan</option>
                    
                    <option value="PW">Palau</option>
                    
                    <option value="PS">Palestine, State of</option>
                    
                    <option value="PA">Panama</option>
                    
                    <option value="PG">Papua New Guinea</option>
                    
                    <option value="PY">Paraguay</option>
                    
                    <option value="PE">Peru</option>
                    
                    <option value="PH">Philippines</option>
                    
                    <option value="PN">Pitcairn</option>
                    
                    <option value="PL">Poland</option>
                    
                    <option value="PT">Portugal</option>
                    
                    <option value="PR">Puerto Rico</option>
                    
                    <option value="QA">Qatar</option>
                    
                    <option value="RE">Réunion</option>
                    
                    <option value="RO">Romania</option>
                    
                    <option value="RU">Russia</option>
                    
                    <option value="RW">Rwanda</option>
                    
                    <option value="BL">Saint Barthélemy</option>
                    
                    <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                    
                    <option value="KN">Saint Kitts and Nevis</option>
                    
                    <option value="LC">Saint Lucia</option>
                    
                    <option value="MF">Saint Martin (French part)</option>
                    
                    <option value="PM">Saint Pierre and Miquelon</option>
                    
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    
                    <option value="WS">Samoa</option>
                    
                    <option value="SM">San Marino</option>
                    
                    <option value="ST">Sao Tome and Principe</option>
                    
                    <option value="SA">Saudi Arabia</option>
                    
                    <option value="SN">Senegal</option>
                    
                    <option value="RS">Serbia</option>
                    
                    <option value="SC">Seychelles</option>
                    
                    <option value="SL">Sierra Leone</option>
                    
                    <option value="SG">Singapore</option>
                    
                    <option value="SX">Sint Maarten (Dutch part)</option>
                    
                    <option value="SK">Slovakia</option>
                    
                    <option value="SI">Slovenia</option>
                    
                    <option value="SB">Solomon Islands</option>
                    
                    <option value="SO">Somalia</option>
                    
                    <option value="ZA">South Africa</option>
                    
                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                    
                    <option value="KR">South Korea</option>
                    
                    <option value="SS">South Sudan</option>
                    
                    <option value="ES">Spain</option>
                    
                    <option value="LK">Sri Lanka</option>
                    
                    <option value="SD">Sudan</option>
                    
                    <option value="SR">Suriname</option>
                    
                    <option value="SJ">Svalbard and Jan Mayen</option>
                    
                    <option value="SE">Sweden</option>
                    
                    <option value="CH">Switzerland</option>
                    
                    <option value="SY">Syria</option>
                    
                    <option value="TW">Taiwan</option>
                    
                    <option value="TJ">Tajikistan</option>
                    
                    <option value="TZ">Tanzania</option>
                    
                    <option value="TH">Thailand</option>
                    
                    <option value="TL">Timor-Leste</option>
                    
                    <option value="TG">Togo</option>
                    
                    <option value="TK">Tokelau</option>
                    
                    <option value="TO">Tonga</option>
                    
                    <option value="TT">Trinidad and Tobago</option>
                    
                    <option value="TN">Tunisia</option>
                    
                    <option value="TR">Turkey</option>
                    
                    <option value="TM">Turkmenistan</option>
                    
                    <option value="TC">Turks and Caicos Islands</option>
                    
                    <option value="TV">Tuvalu</option>
                    
                    <option value="UG">Uganda</option>
                    
                    <option value="UA">Ukraine</option>
                    
                    <option value="AE">United Arab Emirates</option>
                    
                    <option value="GB">United Kingdom</option>
                    
                    <option value="UM">United States Minor Outlying Islands</option>
                    
                    <option value="US">United States of America</option>
                    
                    <option value="UY">Uruguay</option>
                    
                    <option value="UZ">Uzbekistan</option>
                    
                    <option value="VU">Vanuatu</option>
                    
                    <option value="VE">Venezuela</option>
                    
                    <option value="VN">Vietnam</option>
                    
                    <option value="VG">Virgin Islands (British)</option>
                    
                    <option value="VI">Virgin Islands (U.S.)</option>
                    
                    <option value="WF">Wallis and Futuna</option>
                    
                    <option value="EH">Western Sahara</option>
                    
                    <option value="YE">Yemen</option>
                    
                    <option value="ZM">Zambia</option>
                    
                    <option value="ZW">Zimbabwe</option>
                    
                    </select>
                </div>
                <div class=" my-4">
                    <label for="id_gender" class="text-gray-700 text-left text-sm" >Gender<span class="text-red-600">*</span></label>
                    <select placeholder="Gender" class="form-select form-select-lg mb-3
                        appearance-none
                        block
                        w-full
                        px-4
                        py-2
                        text-xl
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" v-model="user.gender" id="id_customer-dual_nationality">
                    <option :value=null disabled>-</option>
                    
                    <option value="Male">Male</option>
                    
                    <option value="Female">Female</option>
                    
                    </select>
                </div>
                <div class="form-floating my-4">
                            <input v-model="user.phone_number" type="tel" class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                            placeholder="Mobile Number" id="floatingInput5" />
                            <label for="floatingInput5" class="text-gray-700" >Mobile Number<span class="text-red-600">*</span></label>
                </div>

                <button @click="addUser()" class=" w-48 m-auto mt-12 mb-5 rounded-md font-medium bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block" >Add</button>
                <!-- <button @click="addUserBook()" class="block w-48 m-auto font-medium border border-t3 p-3 text-t3 rounded-md hover:border-opacity-0 hover:text-white hover:bg-t3-secondary" >Add and Book</button> -->
            </div>


        </div>
    </div>
</Transition>

</template>

<script>
import FileInput from '@/components/FileInput.vue'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref} from 'vue';
import addDays from 'date-fns/addDays';
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';
import ReportServices from '@/services/ReportServices'
import UsersServices from '../services/UsersServices';
import _ from 'lodash';

    export default {
          components: {
                Datepicker,
                FileInput 
            },
        setup() {
        const date = ref(new Date());
         const highlightedDates = ref([
          addDays(new Date(), 1),
          addDays(new Date(), 2),
          addDays(new Date(), 3),
        ])
        
        return {
            date,
            highlightedDates
            
        }
        },
        props:{

        },
        data() {
			return {
                checker: false,
                selected:null,
                suggestions:null,
                currentFile:null,
                user:{
                    email:null,
                    profile_img:null,
                    first_name:null,
                    middle_name:null,
                    last_name:null,
                    dual_nationality:null,
                    nationality:null,
                    dob:null,
                    place_of_birth:null,
                    gender:null,
                    phone_number:null,
                },
            customer_profile:{
                license_type:null,
                other_affiliations:null,
                customer_company:null,
                party:null
            }
			}
		},

        methods: {
            ...mapActions({
                getorganizations: 'Settings/getAllOrganizations',
                gettypes: 'Settings/getAllCustomerTypes',
    
            }),
            debCheck: _.debounce(function(){
		let searchValue = this.first_name
        if(this.middle_name)
        searchValue += " "+this.middle_name

        if(this.last_name)
        searchValue += " "+this.last_name

		if(searchValue){

			this.loading=true
			UsersServices.searchCustomer(searchValue).then((response)=>{
                this.suggestions = response.data
            })
        }
        else{
            this.suggestions = []
        }
         }, 700),
            disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today
    },
        attachmentLoad(event){
            var reader = new FileReader();
        reader.onload = function()
        {
          var output = document.getElementById('output-image');
          output.setAttribute("src",URL.createObjectURL(event.target.files[0]))
          document.getElementById('output-image').load();
        }
        reader.readAsDataURL(event.target.files[0]);
        
        this.currentFile=event.target.value;
       
        },
        addUser(){
               this.checker = true
               for (var i in this.user){
                if((i !== "dual_nationality")&&(i !== "middle_name")){
                    if(this.user[i] === null){
                        this.checker = false
                    }
                }

                }
                if(this.checker){
                    const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                    if (Array.isArray(val)) {
                    val.forEach(v => fd.append(key, v))
                    } else {
                    fd.append(key, val)
                    }
                    return fd
                    }, new FormData())
                    
                    this.user.dob = new Date(this.user.dob).toLocaleDateString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
                    let sender = this.user
                    if(!sender.dual_nationality){
                        delete sender.dual_nationality
                    }
                    UsersServices.addCustomer(getFormData(this.user)).then(()=>{
                    Swal.fire({
                    icon:"success",
                    title:"Success!",
                    text:"User added successfully!",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,})
                    }).catch((error)=>{
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                    })
                }
                else{
                    Swal.fire({
                    icon:"warning",
                    title:"Try again",
                    text:"Please fill in all required the fields.",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,})
                            
                        }
            }
         },

        computed: {
            ...mapState({
                organizations: state => state.Settings.organizations,
                types: state => state.Settings.customer_types,
            })
		},

        created() {
			
            this.getorganizations()
            this.gettypes()
		},
    }
</script>

<style scoped>
#search:focus+#list{
    display: flex;
}
#search:not(focus)+.lister{
    border:none;
}
.searcher:focus{
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
#list:hover{
    display: flex;
}
.dp__main{
    padding:0 !important
}
</style>
